import { computed, inject, Injectable } from '@angular/core';
import {
  CenterChildDto,
  CenterChildService,
  ChildMemoDto,
  ChildMemoService,
} from '@malirang/api-client';
import { BaseStore } from '@malirang/front-share';

type State = {
  centerChild: CenterChildDto | null;
  memos: ChildMemoDto[];
};

@Injectable({ providedIn: 'root' })
export class DetailChildStore extends BaseStore<State> {
  readonly memoService = inject(ChildMemoService);
  readonly centerChildService = inject(CenterChildService);

  child = computed(() => this.centerChild()?.child);
  memos = computed(() => this.state()?.memos);
  centerChild = computed(() => this.state()?.centerChild);

  infoMemos = computed(() =>
    this.state()?.memos?.filter((memo) => memo.type === 'INFO'),
  ); // 배경정보/주호소
  centerMemos = computed(() =>
    this.state()?.memos?.filter(
      (memo) => memo.type === 'MEMO' && (memo.teacher || memo.guardian),
    ),
  ); // 센터메모(선생님 작성)

  constructor() {
    super({
      centerChild: null,
      memos: [],
    });
  }

  fetchCenterChild(id: string) {
    this.centerChildService
      .userCenterChildControllerFindById({ id })
      .subscribe({
        next: (res) => {
          this.setCenterChild(res);
        },
      });
  }

  fetchMemos(centerId: string, childId: string) {
    this.memoService
      .userChildMemoControllerFindMemoByCenter({
        centerId,
        childId,
      })
      .subscribe({
        next: (memos) => {
          this.setMemos(memos);
        },
      });
  }

  setCenterChild(centerChild: CenterChildDto) {
    this.updateState({ centerChild });
  }

  setMemos(memos: ChildMemoDto[]) {
    this.updateState({ memos });
  }

  clearChild() {
    this.updateState({ centerChild: null });
  }
}
